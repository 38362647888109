import { useState, useEffect } from 'react';
import { getHedgeRecommendations } from 'services/firebase/analysis';
import { Notify } from 'utils';

export type IHedgeRecommendationPeriodTypes = 'week' | 'month';

export type IHedgeRecommendationResult = {
  period: number;
  buyCurrency: string;
  sellCurrency: string;
  currency: string;
  /** In future this may also include week */
  periodType: IHedgeRecommendationPeriodTypes;
  /** To be displayed by UI */
  periodAsText: string;
  from: string;
  expiryDate: string;
  newHedgeAmount: number;
  expectedCashFlowsAmount: number;
  existingHedgeAmount: number;
  newHedgeRatio: number;
  message?: string;
  // Make optional as we might the UI to show this
  rate?: number;
};

/**
 * API response with IHedgeRecommendationResult per currency
 */
export type IHedgeRecommendationResults = Record<
  string,
  IHedgeRecommendationResult[]
>;

export const useGetHedgeRecommendations = ({
  calendarInterval,
  numberOfRecommendations,
}: {
  calendarInterval: 'month' | 'week';
  numberOfRecommendations: number;
}) => {
  const [
    isLoadingRecommendations,
    setIsLoadingRecommendations,
  ] = useState<boolean>(false);
  const [
    recommendations,
    setRecommendations,
  ] = useState<IHedgeRecommendationResults>();

  useEffect(() => {
    const fetchRecommendations = async () => {
      setIsLoadingRecommendations(true);
      try {
        const results = await getHedgeRecommendations({
          calendarInterval,
          timePeriods: numberOfRecommendations,
        });
        if (!!results?.data?.data) {
          setRecommendations(results.data.data);
        }
      } catch (error: any) {
        Notify.error(error.message);
      } finally {
        setIsLoadingRecommendations(false);
      }
    };

    fetchRecommendations();
  }, [calendarInterval, numberOfRecommendations, setRecommendations]);

  return { isLoadingRecommendations, recommendations, setRecommendations };
};
