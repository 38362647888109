import { FC, useState, InputHTMLAttributes } from 'react';
import { IMaskInput } from 'react-imask';
import { InputConvertWrapper } from './InputConvert.styles';
import { Masks } from 'utils';
import { useTheme } from 'styled-components';
import { Paragraph } from '../Typography/Typography';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';

interface IOwnProps {
  value: number;
  onChange?: (value: unknown) => unknown;
  onFocus?: () => void;
  onBlur?: () => void;
  onSubmit?: (value: unknown) => unknown;
  autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus'];
  precision?: number;
  symbol?: string;
  max?: number;
}

const InputConvert: FC<IOwnProps> = ({
  value,
  onSubmit,
  onChange,
  onFocus,
  onBlur,
  autoFocus = false,
  precision = 2,
  symbol,
  max,
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [changedValue, setChangedValue] = useState(value);
  const sanitizedValue = value < 0 ? value * -1 : value;

  const onSubmitHandler = () => {
    onSubmit?.(changedValue);
    setIsFocused(false);
  };

  return (
    <InputConvertWrapper focused={isFocused}>
      {symbol && (
        <Paragraph ml mlValue={theme.spacing.xs}>
          {symbol}
        </Paragraph>
      )}

      <IMaskInput
        className="input-convert"
        mask={Number}
        radix="."
        unmask="typed"
        placeholder="0.00"
        autoComplete="off"
        inputMode="decimal"
        thousandsSeparator=","
        max={max}
        value={
          changedValue
            ? Masks.numberToCurrencyNumberString(changedValue, precision)
            : undefined
        }
        onAccept={(e: unknown) => {
          setChangedValue(e as number);
          onChange?.(e);
        }}
        onFocus={() => {
          onFocus?.();
          setIsFocused(true);
        }}
        onBlur={() => {
          onBlur?.();
          onSubmitHandler();
        }}
        autoFocus={autoFocus}
      />

      {sanitizedValue !== changedValue && (
        <Button
          mt
          mtValue={theme.spacing.xs}
          flex={1}
          variant="link"
          onClick={onSubmitHandler}
        >
          <Icon icon="checkbox-checkmark" />
        </Button>
      )}
    </InputConvertWrapper>
  );
};

export default InputConvert;
