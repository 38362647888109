import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { parseRateWithPrecision } from 'utils';
import Icon from '../Icon/Icon';
import InlineLoader from '../InlineLoader/InlineLoader';
import { Row } from '../Row/Row';
import { Paragraph } from '../Typography/Typography';

interface IOwnProps {
  rate?: number | null;
  sellCurrencyCode: string;
  buyCurrencyCode: string;
  isRateLoading?: boolean;
}

const ExchangeRate: FC<IOwnProps> = ({
  rate,
  sellCurrencyCode,
  buyCurrencyCode,
  isRateLoading,
}) => {
  const theme = useTheme();
  const [isInverted, setIsInverted] = useState(false);

  const firstCurrency = !isInverted ? sellCurrencyCode : buyCurrencyCode;
  const secondCurrency = !isInverted ? buyCurrencyCode : sellCurrencyCode;

  return (
    <Row flex={1}>
      <Paragraph>Exchange rate</Paragraph>
      <Row>
        <Paragraph mr variant="bold">
          {`1 ${firstCurrency} = `}
          {isRateLoading || !rate ? (
            <InlineLoader />
          ) : (
            `${parseRateWithPrecision(
              isInverted ? 1 / rate : rate
            )} ${secondCurrency}`
          )}
        </Paragraph>

        <Icon
          style={{ cursor: 'pointer' }}
          type="button"
          onClick={() => setIsInverted((prev) => !prev)}
          fill={theme.color.greyDark}
          icon="convert-ico"
        />
      </Row>
    </Row>
  );
};

export default ExchangeRate;
