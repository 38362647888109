import { Dispatch, SetStateAction } from 'react';
import dayjs from 'dayjs';
import { CellProps, Column } from 'react-table';
import { Paragraph } from 'components';
import { IHedgeRecommendationResult } from './useGetHedgeRecommendations';
import CellAmountWithDirection from 'components/shared/CellAmountWithDirection/CellAmountWithDirection';
import CurrencyCell from 'components/shared/CurrencyCell/CurrencyCell';
import NewHedgeRatioCell from './components/NewHedgeRatioCell/NewHedgeRatioCell';
import NewHedgeAmountCell from './components/NewHedgeAmountCell/NewHedgeAmountCell';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import { DAY_MONTH_DATE_FORMAT } from 'variables';
import { parseRateWithPrecision } from 'utils';

export const generateTableColumns = ({
  setHedgeRecommendationForPrebook,
  onEditNewHedgeAmount,
}: {
  setHedgeRecommendationForPrebook: Dispatch<
    SetStateAction<IHedgeRecommendationResult | undefined>
  >;
  onEditNewHedgeAmount: (updatedData: IHedgeRecommendationResult) => void;
}): Column<IHedgeRecommendationResult>[] => [
  {
    accessor: 'periodAsText',
    Header: 'Period',
    Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
    width: 80,
    minWidth: 60,
  },
  {
    accessor: 'currency',
    Header: 'Currency',
    Cell: ({ value }) => <CurrencyCell currencyCode={value} />,
    width: 80,
    minWidth: 60,
  },
  {
    accessor: 'expectedCashFlowsAmount',
    Header: 'Expected Cashflows',
    Cell: ({ value, row }) => (
      <CellAmountWithDirection
        value={value}
        direction={value > 0 ? 'in' : 'out'}
        currencyCode={row.original.currency}
        showDirection={value !== 0}
      />
    ),
    width: 150,
    minWidth: 130,
  },
  {
    accessor: 'existingHedgeAmount',
    Header: 'Existing Hedges',
    Cell: ({ value, row }) => (
      <CellAmountWithDirection
        value={value}
        direction={value > 0 ? 'in' : 'out'}
        currencyCode={row.original.currency}
        showDirection={value !== 0}
      />
    ),
    width: 150,
    minWidth: 130,
  },
  {
    accessor: 'newHedgeAmount',
    Header: 'New Hedge Amount',
    Cell: ({ row }) => (
      <NewHedgeAmountCell
        record={row.original}
        onEditNewHedgeAmount={onEditNewHedgeAmount}
      />
    ),

    width: 150,
    minWidth: 130,
  },
  {
    accessor: 'expiryDate',
    Header: 'Expiry Date',
    Cell: ({ value }) => (
      <Paragraph>{dayjs(value).format(DAY_MONTH_DATE_FORMAT)}</Paragraph>
    ),
    width: 80,
    minWidth: 60,
  },
  {
    accessor: 'rate',
    Header: 'Indic. Rate',
    Cell: ({ value }: CellProps<IHedgeRecommendationResult>) => (
      <Paragraph>{parseRateWithPrecision(value) || '-'}</Paragraph>
    ),
    width: 80,
    minWidth: 60,
  },
  {
    id: 'newHedgeRatio',
    Header: 'New Hedge Ratio',
    Cell: ({ row }: CellProps<IHedgeRecommendationResult>) => (
      <NewHedgeRatioCell record={row.original} />
    ),
    width: 130,
    minWidth: 130,
  },
  {
    id: 'action',
    Header: () => null,
    Cell: ({ row }: CellProps<IHedgeRecommendationResult>) => (
      <ActionButton
        disabled={false}
        onClick={() => setHedgeRecommendationForPrebook(row.original)}
      >
        Hedge FX
      </ActionButton>
    ),
    width: 100,
    minWidth: 100,
  },
];
