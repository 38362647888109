import { FC, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Button, Popup, Row, Title } from 'components';
import { useTheme } from 'styled-components';
import {
  IHedgeRecommendationResult,
  IHedgeRecommendationResults,
} from 'pages/HedgeRecommendations/useGetHedgeRecommendations';
import { useStoreState } from 'state';
import { roundToPrecision } from 'utils';
import dayjs from 'dayjs';
import { createRateContract } from 'services/firebase';
import { DB_DATE_FORMAT } from 'variables';
import ConvertInfo from './components/ConvertInfo/ConvertInfo';
import { errorHandler } from 'utils/errors';
import usePrebookRate from 'pages/Prebook/hooks/usePrebookRate';

interface IOwnProps {
  onClose: () => void;
  hedgeRecommendationForPrebook: IHedgeRecommendationResult;
  setRecommendations: Dispatch<
    SetStateAction<IHedgeRecommendationResults | undefined>
  >;
}

const PrebookHedgesPopup: FC<IOwnProps> = ({
  onClose,
  hedgeRecommendationForPrebook,
  setRecommendations,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [isPrebookCreating, setIsPrebookCreating] = useState(false);

  const {
    rate,
    bookingFeeRate,
    conversionFeeRate,
    isRateLoading,
    getFwdRateAndFees,
  } = usePrebookRate({
    sellCurrencyCode: hedgeRecommendationForPrebook.sellCurrency,
    buyCurrencyCode: hedgeRecommendationForPrebook.buyCurrency,
  });

  const sellAmountAsNumber = hedgeRecommendationForPrebook.newHedgeAmount;
  const buyAmountAsNumber = sellAmountAsNumber * (rate || 0);
  const sellCurrency = currencyByCode(
    hedgeRecommendationForPrebook.sellCurrency
  );
  const buyCurrency = currencyByCode(hedgeRecommendationForPrebook.buyCurrency);

  const onPrebookHandler = async () => {
    try {
      if (!rate || !sellCurrency || !buyCurrency) {
        return undefined;
      }

      setIsPrebookCreating(true);

      const bookingFeeAmount = roundToPrecision(
        sellAmountAsNumber * bookingFeeRate
      );
      const sellAmountForBackend = roundToPrecision(
        sellAmountAsNumber - bookingFeeAmount
      );
      const buyAmountForBackend = roundToPrecision(buyAmountAsNumber);
      const totalAmountForBackend = roundToPrecision(sellAmountAsNumber);

      const data = await createRateContract({
        // booking fee is included into sell amount on the client, but we need pure sell amount on the BE
        sellCurrency: hedgeRecommendationForPrebook.sellCurrency,
        buyCurrency: hedgeRecommendationForPrebook.buyCurrency,

        sellAmount: sellAmountForBackend,
        buyAmount: buyAmountForBackend,
        rate,
        feeRate: bookingFeeRate,
        feeAmount: bookingFeeAmount,
        conversionFeeRate,
        totalAmount: totalAmountForBackend,
        expiryDate: dayjs(hedgeRecommendationForPrebook.expiryDate).format(
          DB_DATE_FORMAT
        ),
      });

      if (data?.success) {
        setRecommendations((prevRecommendations) => {
          if (!prevRecommendations) {
            return undefined;
          }

          const updatedRecommendation = prevRecommendations[
            hedgeRecommendationForPrebook.currency
          ].find(
            (recommendation) =>
              recommendation.period === hedgeRecommendationForPrebook.period
          );

          if (!updatedRecommendation) {
            return;
          }

          return {
            ...prevRecommendations,
            [hedgeRecommendationForPrebook.currency]: prevRecommendations[
              hedgeRecommendationForPrebook.currency
            ].map((recommendation) =>
              recommendation.period === hedgeRecommendationForPrebook.period
                ? {
                    ...recommendation,
                    existingHedgeAmount:
                      updatedRecommendation.existingHedgeAmount +
                      (updatedRecommendation.expectedCashFlowsAmount < 0
                        ? 1
                        : -1) *
                        data.data.totalAmount,
                    newHedgeAmount: 0,
                  }
                : recommendation
            ),
          };
        });

        onClose();
      } else {
        errorHandler(data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsPrebookCreating(false);
    }
  };

  useEffect(() => {
    getFwdRateAndFees({
      sellCurrency: hedgeRecommendationForPrebook.sellCurrency,
      buyCurrency: hedgeRecommendationForPrebook.buyCurrency,
      dateString: dayjs(hedgeRecommendationForPrebook.expiryDate).format(
        DB_DATE_FORMAT
      ),
    });
  }, [hedgeRecommendationForPrebook, getFwdRateAndFees]);

  useEffect(() => {
    if (rate) {
      setRecommendations((prevRecommendations) => {
        if (!prevRecommendations) {
          return undefined;
        }

        const updatedRecommendationsForCurrency = prevRecommendations[
          hedgeRecommendationForPrebook.currency
        ].map((recommendation) => ({
          ...recommendation,
          rate:
            hedgeRecommendationForPrebook.expectedCashFlowsAmount < 0
              ? 1 / rate
              : rate,
        }));

        return {
          ...prevRecommendations,
          [hedgeRecommendationForPrebook.currency]: updatedRecommendationsForCurrency,
        };
      });
    }
  }, [hedgeRecommendationForPrebook, rate, setRecommendations]);

  return (
    <Popup
      HeaderContent={<Title variant="h3">Confirm hedge</Title>}
      FooterContent={
        <Row flex={1} gap={theme.spacing.m}>
          <Button
            variant="secondary"
            disabled={isPrebookCreating || isRateLoading}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isPrebookCreating || isRateLoading}
            isLoading={isRateLoading || isPrebookCreating}
            variant="primary"
            onClick={onPrebookHandler}
          >
            Confirm
          </Button>
        </Row>
      }
      width="439px"
      onClose={onClose}
    >
      {buyCurrency && sellCurrency && (
        <ConvertInfo
          sellCurrency={sellCurrency}
          buyCurrency={buyCurrency}
          rate={rate}
          sellAmountAsNumber={sellAmountAsNumber}
          buyAmountAsNumber={buyAmountAsNumber}
          bookingFeeAmount={sellAmountAsNumber * bookingFeeRate}
          date={new Date(hedgeRecommendationForPrebook.expiryDate)}
          fromDate={new Date(hedgeRecommendationForPrebook.from)}
          isRateLoading={isRateLoading}
        />
      )}
    </Popup>
  );
};

export default PrebookHedgesPopup;
