import { FC } from 'react';
import dayjs from 'dayjs';
import { Paragraph, TextHint, Row, Icon, Col, InlineLoader } from 'components';
import { parseIntoCurrencyString } from 'utils';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { useTheme } from 'styled-components';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';
import { DAY_MONTH_DATE_FORMAT } from 'variables';
import ExchangeRate from 'components/shared/ExchangeRate/ExchangeRate';
import { ICurrency } from 'types';

interface IConvertInfoProps {
  sellCurrency: ICurrency;
  buyCurrency: ICurrency;
  date: Date;
  fromDate: Date;
  buyAmountAsNumber: number;
  bookingFeeAmount: number;
  sellAmountAsNumber: number;
  isRateLoading: boolean;
  rate?: UseCurrencyRateReturnValues['rate'];
}

const ConvertInfo: FC<IConvertInfoProps> = ({
  sellCurrency,
  buyCurrency,
  date,
  fromDate,
  buyAmountAsNumber,
  bookingFeeAmount,
  sellAmountAsNumber,
  isRateLoading,
  rate,
}) => {
  const theme = useTheme();
  const {
    code: sellCurrencyCode,
    precision: sellCurrencyPrecision,
    countryCode: sellCurrencyCountryCode,
  } = sellCurrency;
  const {
    code: buyCurrencyCode,
    precision: buyCurrencyPrecision,
    countryCode: buyCurrencyCountryCode,
  } = buyCurrency;

  return (
    <Col>
      <RowInfo>
        <Paragraph>You sell</Paragraph>
        <Row>
          <Paragraph mr variant="bold">
            {`${sellCurrencyCode} ${parseIntoCurrencyString(
              sellAmountAsNumber,
              sellCurrencyPrecision
            )}
          `}
          </Paragraph>
          <Icon icon={sellCurrencyCountryCode} />
        </Row>
      </RowInfo>

      <Row style={{ paddingRight: 40 }}>
        <TextHint>Including booking fee</TextHint>
        <TextHint>
          {isRateLoading || !rate ? (
            <InlineLoader />
          ) : (
            `${sellCurrencyCode} ${parseIntoCurrencyString(
              bookingFeeAmount,
              sellCurrencyPrecision
            )}`
          )}
        </TextHint>
      </Row>

      <RowInfo>
        <Paragraph>You buy</Paragraph>
        <Row>
          <Paragraph mr variant="bold">
            {isRateLoading || !rate ? (
              <InlineLoader />
            ) : (
              `${buyCurrencyCode} ${parseIntoCurrencyString(
                buyAmountAsNumber,
                buyCurrencyPrecision
              )}`
            )}
          </Paragraph>
          <Icon icon={buyCurrencyCountryCode} />
        </Row>
      </RowInfo>

      <RowInfo>
        <ExchangeRate
          sellCurrencyCode={sellCurrencyCode}
          buyCurrencyCode={buyCurrencyCode}
          rate={rate}
          isRateLoading={isRateLoading}
        />
      </RowInfo>

      <RowInfo>
        <Paragraph>Exchange from</Paragraph>
        <Row>
          <Paragraph mr variant="bold">
            {dayjs(fromDate).format(DAY_MONTH_DATE_FORMAT)}
          </Paragraph>

          <Icon fill={theme.color.greyDark} icon="calendar-ico" />
        </Row>
      </RowInfo>

      <RowInfo>
        <Paragraph>Exchange until</Paragraph>
        <Row>
          <Paragraph mr variant="bold">
            {dayjs(date).format(DAY_MONTH_DATE_FORMAT)}
          </Paragraph>

          <Icon fill={theme.color.greyDark} icon="calendar-ico" />
        </Row>
      </RowInfo>
    </Col>
  );
};

export default ConvertInfo;
