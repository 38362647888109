import styled from 'styled-components';

interface IOwnProps {
  error?: boolean;
  focused: boolean;
}
export const InputConvertWrapper = styled.div<IOwnProps>`
  display: flex;
  align-items: center;
  position: relative;

  color: ${({ theme }) => theme.color.dark};
  background: ${({ theme }) => theme.color.greyLight_3};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  transition: ${({ theme }) => theme.transition};

  box-shadow: ${({ theme, focused }) =>
    focused && `inset 0 0 0 1px ${theme.color.emeraldDark}`};

  .input-convert {
    padding: ${({ theme }) => theme.spacing.xs};
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.s};
    transition: ${({ theme }) => theme.transition};
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    letter-spacing: 0.03em;

    &[type='number'],
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      margin: 0;
    }
  }
`;
