import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { useState, useEffect, useCallback } from 'react';

import {
  GeForwardRateAndFeesParams,
  getForwardRateAndFees,
} from 'services/firebase/rates';
import { ICurrency, IForwardRateAndFees } from 'types';
import useCurrencyRate, {
  UseCurrencyRateReturnValues,
} from 'hooks/useCurrencyRate';
import { DB_DATE_FORMAT } from 'variables';

interface UsePrebookRateParams {
  buyCurrencyCode: string;
  sellCurrencyCode: string;
  date?: Date;
  useableFrom?: Dayjs;
}

export interface UsePrebookRateReturnValues {
  rate: UseCurrencyRateReturnValues['rate'];
  conversionFeeRate: number;
  flexFeeRate: number;
  bookingFeeRate: number;
  isRateLoading: boolean;
  isGetRateError: boolean;
  getFwdRateAndFees: (
    params: GeForwardRateAndFeesParams
  ) => Promise<number | undefined>;
  getSpotRate: (
    sellCurrency: ICurrency['code'],
    buyCurrency: ICurrency['code']
  ) => Promise<number | undefined>;
}

const usePrebookRate = ({
  buyCurrencyCode,
  sellCurrencyCode,
  date,
  useableFrom,
}: UsePrebookRateParams): UsePrebookRateReturnValues => {
  const [isGetRateError, setIsGetRateError] = useState(false);
  const [
    fwdRateAndFees,
    setFwdRateAndFees,
  ] = useState<IForwardRateAndFees | null>(null);

  const {
    rate: spotRate,
    isLoading: isSpotRateLoading,
    isError: isGetSpotRateError,
    conversionFeeRate: spotConversionFeeRate,
    getRate: getSpotRate,
  } = useCurrencyRate({
    buyCurrency: buyCurrencyCode,
    sellCurrency: sellCurrencyCode,
  });

  const getFwdRateAndFees = useCallback(
    async ({
      sellCurrency,
      buyCurrency,
      dateString,
      useableFromDateString,
    }: GeForwardRateAndFeesParams) => {
      try {
        setFwdRateAndFees(null);

        const rateResponse = await getForwardRateAndFees({
          sellCurrency,
          buyCurrency,
          dateString,
          useableFromDateString,
        });

        /* 
          TODO: remove this condition when we will start returning 
          correct status codes etc. from the server
        */
        if (rateResponse?.success && rateResponse.data) {
          setFwdRateAndFees(rateResponse.data);

          setIsGetRateError(false);
          return rateResponse.data.rate;
        } else {
          setIsGetRateError(true);
        }
      } catch (error: any) {
        if (axios.isCancel(error)) {
          console.warn('getRate request was canceled');
        } else {
          setIsGetRateError(true);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (buyCurrencyCode && sellCurrencyCode && date) {
      getFwdRateAndFees({
        sellCurrency: sellCurrencyCode,
        buyCurrency: buyCurrencyCode,
        dateString: dayjs(date).format(DB_DATE_FORMAT),
        useableFromDateString:
          !!useableFrom && dayjs.isDayjs(useableFrom)
            ? useableFrom.format(DB_DATE_FORMAT)
            : undefined,
      });
    }
  }, [buyCurrencyCode, date, getFwdRateAndFees, useableFrom, sellCurrencyCode]);

  return {
    rate: fwdRateAndFees?.rate || spotRate,
    conversionFeeRate:
      fwdRateAndFees?.conversionFeeRate || spotConversionFeeRate,
    flexFeeRate: fwdRateAndFees?.flexFeeRate || 0,
    bookingFeeRate: fwdRateAndFees?.bookingFeeRate || 0,
    isRateLoading: !fwdRateAndFees || isSpotRateLoading,
    isGetRateError: isGetRateError || isGetSpotRateError,
    getFwdRateAndFees,
    getSpotRate,
  };
};

export default usePrebookRate;
