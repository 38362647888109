import { FC } from 'react';
import { IHedgeRecommendationResult } from 'pages/HedgeRecommendations/useGetHedgeRecommendations';
import { Row } from 'components';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import InputConvert from 'components/shared/InputConvert/InputConvert';
import InflowsIcon from 'components/shared/InflowsIcon/InflowsIcon';
import OutflowsIcon from 'components/shared/OutflowsIcon/OutflowsIcon';

interface IOwnProps {
  record: IHedgeRecommendationResult;
  onEditNewHedgeAmount: (updatedData: IHedgeRecommendationResult) => void;
}

const NewHedgeAmountCell: FC<IOwnProps> = ({
  record,
  onEditNewHedgeAmount,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(record.currency);
  const positiveDirection = record.expectedCashFlowsAmount > 0;
  const showDirection = record.expectedCashFlowsAmount !== 0;

  if (!currency) {
    return null;
  }

  return (
    <Row gap={theme.spacing.xxs}>
      {showDirection && !positiveDirection && <InflowsIcon />}
      {showDirection && positiveDirection && <OutflowsIcon />}

      <InputConvert
        value={record.newHedgeAmount}
        onSubmit={(value) =>
          onEditNewHedgeAmount({ ...record, newHedgeAmount: value as number })
        }
        precision={currency.precision}
        symbol={currency.symbol}
        max={500000}
      />
    </Row>
  );
};

export default NewHedgeAmountCell;
