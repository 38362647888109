import { FC, useMemo, useState, useCallback } from 'react';
import {
  IHedgeRecommendationResult,
  useGetHedgeRecommendations,
} from './useGetHedgeRecommendations';
import Loader from 'components/shared/Loader/Loader';
import { generateTableColumns } from './generateTableColumns';
import { Row, StaleInputSelect, Table } from 'components';
import { useStoreState } from 'state';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import { useTheme } from 'styled-components';
import PrebookHedgesPopup from './components/PrebookHedgesPopup/PrebookHedgesPopup';

const HedgeRecommendations: FC = () => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [
    hedgeRecommendationForPrebook,
    setHedgeRecommendationForPrebook,
  ] = useState<IHedgeRecommendationResult>();
  const [selectedCurrency, setSelectedCurrency] = useState<string>();
  const [calendarInterval, setCalendarInterval] = useState<'month' | 'week'>(
    'month'
  );

  const {
    isLoadingRecommendations,
    recommendations,
    setRecommendations,
  } = useGetHedgeRecommendations({
    calendarInterval,
    numberOfRecommendations: 12,
  });

  const currenciesList = useMemo(() => {
    if (!recommendations) {
      return [];
    }

    const currencies = Object.keys(recommendations).map((currency) => ({
      id: currency,
      name: currency,
      value: currency,
      icon: currencyByCode(currency)?.countryCode,
    }));

    // Set default currency
    if (!selectedCurrency && currencies[0]) {
      setSelectedCurrency(currencies[0].id);
    }

    return currencies;
  }, [recommendations, currencyByCode, selectedCurrency]);

  const tableData = useMemo(() => {
    if (!selectedCurrency || !recommendations) {
      return [];
    }

    return recommendations[selectedCurrency];
  }, [recommendations, selectedCurrency]);

  const onEditNewHedgeAmount = useCallback(
    (updatedData: IHedgeRecommendationResult) => {
      if (!recommendations || !selectedCurrency) {
        return;
      }

      const updatedRecommendation = recommendations[selectedCurrency].find(
        (recommendation) => recommendation.period === updatedData.period
      );

      // Check if the new hedge amount is different from the previous one
      if (
        !updatedRecommendation ||
        updatedRecommendation.newHedgeAmount === updatedData.newHedgeAmount
      ) {
        return;
      }

      setRecommendations((prevRecommendations) => {
        if (!prevRecommendations || !selectedCurrency) {
          return prevRecommendations;
        }

        return {
          ...prevRecommendations,
          [selectedCurrency]: prevRecommendations[
            selectedCurrency
          ].map((recommendation) =>
            recommendation.period === updatedData.period
              ? updatedData
              : recommendation
          ),
        };
      });
    },
    [recommendations, selectedCurrency, setRecommendations]
  );

  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        setHedgeRecommendationForPrebook,
        onEditNewHedgeAmount,
      }),
    [onEditNewHedgeAmount]
  );

  if (isLoadingRecommendations) {
    return <Loader size="large" />;
  }

  return (
    <>
      <TableHeader>
        <Row gap={theme.spacing.m}>
          <StaleInputSelect
            id="cashflow-currencies"
            inputHeight="32px"
            style={{ minWidth: '132px' }}
            data={currenciesList}
            selected={selectedCurrency}
            onSelect={(item) => setSelectedCurrency(item.value)}
          />

          <StaleInputSelect
            id="cashflow-interval"
            inputHeight="32px"
            style={{ minWidth: '132px' }}
            data={[
              {
                id: 'month',
                name: 'Month',
                value: 'month',
              },
              {
                id: 'week',
                name: 'Week',
                value: 'week',
              },
            ]}
            selected={calendarInterval}
            onSelect={(item) => setCalendarInterval(item.value)}
          />
        </Row>
      </TableHeader>

      <Table<IHedgeRecommendationResult>
        autoResetGlobalFilter={false}
        autoResetSortBy={false}
        autoResetFilters={false}
        data={tableData}
        globalFilter="text"
        sortable
        columns={tableColumns}
        isVirtualized
        minVisibleRows={6}
        defaultRowHeight={58}
      />

      {hedgeRecommendationForPrebook && (
        <PrebookHedgesPopup
          hedgeRecommendationForPrebook={hedgeRecommendationForPrebook}
          onClose={() => setHedgeRecommendationForPrebook(undefined)}
          setRecommendations={setRecommendations}
        />
      )}
    </>
  );
};

export default HedgeRecommendations;
